<template>
  <div class="stations">
    <v-container>
      <v-row class="ml-1 mt-2">
        <v-col cols="12" sm="12" md="4" lg="3" class="mt-1">
          <v-text-field v-model="findTxt" hide-details outlined dense clearable label="Find" prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3">
          <v-select
            v-model="satelliteSelect"
            :items="satelliteItems"
            label="Satellite"
            multiple
            chips
            hide-details
            clearable
            dense
            prepend-inner-icon="mdi-filter-menu"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:selection="data">
              <v-chip
                :class="data.item"
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                @click:close="satelliteSelect.splice(satelliteSelect.indexOf(data.item), 1)"
                close
              >
                {{ data.item }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-layout row wrap v-if="!isLoading">
        <v-flex xs12 sm12 pa-4>
          <div v-if="stations.length > 0">
            <div v-for="station in stations" :key="`${station._id}`">
              <Station :station="station"/>
              <v-divider></v-divider>
            </div>
          </div>
          <div v-else align="center">
            <div class="pt-2">No stations</div>
          </div>
        </v-flex>
      </v-layout>
      <v-row v-else class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </v-row>

      <!-- pagination -->
      <v-row justify="center">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination v-show="pagination?.pageCount > 1"
              v-model="page"
              :length="pagination?.pageCount || 1"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useApi } from "../hooks/useApi"

import Station from '../components/Station.vue'

const stationApi = useApi().station

export default {
  name:"Stations",
  components: {
    Station
  },
  data() {
    return {
      isLoading: true,
      timeoutId: null,

      // dataTable
      page: 1,
      limit: 8,
      select: "name status satellite version lastPacketTime lastSeen remoteTune test telemetryPackets confirmedPackets",
      sort: "lastSeen",
      pagination: {},

      satelliteSelect: [],
      satelliteItems: [],
      findTxt: "",
      stations: null,
      origStations: []
    }
  },
  beforeMount() {
    this.getStations()
  },
  methods:{
    async getStations() {
      this.isLoading = true;

      const { success, data, pagination } = await stationApi.get(this.page, this.limit, this.sort, this.findTxt || '', this.select);

      this.isLoading = false;
      this.pagination = pagination
      this.satelliteItems = data.map(item => item.satellite).filter((value, index, self) => self.indexOf(value) === index)
      this.stations = data;
      this.origStations = data;
    },
  },
  watch: {
    findTxt() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.getStations()
      }, 1500);

    },
    satelliteSelect(satSelect) {
      if (!satSelect.length) {
        this.stations = this.origStations
        return
      }
      this.stations = this.origStations.filter(
        (value, idx) => {
          return satSelect.filter(
            (sat, id) => sat === value.satellite
          ).length > 0
        }
      )
      // this.page = 1
    },
    page() {
      this.getStations()
    }
  },

}
</script>

<style>


</style>
